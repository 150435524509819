@import '../../scss/_vars/colors';

.header {
  height: 111px;
  background: linear-gradient(to right, $primary, $secondary);
  padding-left: 96px;
  padding-right: 96px;

  @media(max-width:990px){
    padding-left: 15px;
    padding-right: 0px;
  }

  .menuItem {
    display: flex;
    padding: 4px 12px 4px 12px;
    color: white;
    align-items: center;
  }

  .header_nav_link_active {
    display: flex;
    align-items: center;
    padding: 4px 12px 4px 12px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 1);
    color: $primary;

    svg,
    path {
      fill: $primary !important;
    }
  }

  .drawer{
    cursor: pointer;
    margin: 0 0.25rem;
    display: none;
  }

  @media screen and (max-width: 990px){
    .drawer{
      display: inline-block;
    }
  }
}

.main {
  height: calc(100vh - 126px);
  margin-top: 15px;

  .sidebar {
    width: 240px;
    background-color: white;
    margin-right: 30px;

    .activeSidebarItem {
      background: linear-gradient(to right, $primary, $secondary);
      padding: 8px 16px 8px 16px;
      margin-bottom: 10px;
      color: white;
      cursor: pointer;
    }

    .sidebarItem {
      background: white;
      padding: 8px 16px 8px 16px;
      margin-bottom: 10px;
      color: black;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 990px){
    .open{
      z-index: 1000;
      width: 100%;
      height: calc(100vh - 111px);
      position: absolute;  
      transform: translateX(0%);
      transition: transform 0.5s;
      margin-top: -15px;
    }
    .close{
      position: absolute;
      transform: translateX(-100%);
      height: calc(100vh - 111px);
      z-index: -1;
      transition: transform 0.5s, z-index 0s 1s;
      margin-top: -15px;
    }
  }

  .contentWrapper {
    margin-right: 15px;
    width: calc(100vw - 330px);
    @media screen and (max-width: 990px){
      width: 100%;
      margin-right: 0px;
    }
  }
}

.wrapper {
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(188, 188, 188, 1);
  border-radius: 25px;
  padding: 15px;
  height: 100%;
  width: 104%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.description {
  overflow-wrap:break-word;
}

@import '../../../../../scss/_vars/colors';


.postWrapper {
  // margin-top: -60px !important;
  background-color: white !important;
  padding: 15px 10px 10px 15px;
  border-radius: 16px 16px 16px 16px !important;

  .titleFont{
    @media (max-width: 990px) {
      font-size: 24px !important;
    }
  }

  @media (max-width: 990px) {
    .titleContainer {
      flex-direction: column;
      align-items: center;
    }
    .titleCard {
      max-width: unset;
    }

    .sourceContainer{
      padding: 0px 0px 0px 0px;
      margin: 0px 0px 0px 0px;
      justify-content: start;
    }

    .dateContainer {
      min-width: max-content;
      margin-top: 10px;
      margin-bottom: 10px;
      @media (max-width: 990px){
        width: 100%;
        max-width: unset;
        justify-content: start;
      }
    }
  }
  
}

.headerWrapper{
  min-height: 89px;
  background: linear-gradient(to right,$primary, $secondary);
  border-radius: 0px 0px 16px 16px;
  margin-top: -47px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
}

@media screen and (max-width: 900px) {
  .headerWrapper {
    flex-direction: column;
    margin-top: -24px;
  }
}
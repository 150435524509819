.ck-toolbar {
  background-color: rgba(226, 226, 226, 1) !important;
}

.ck-content {
  p,
  span {
    font-weight: 100 !important;
  }

  a {
    color: blue;
    text-decoration: underline;
    word-wrap: break-word;
  }

  *{
    font-family: Georgia, serif;
  }
}

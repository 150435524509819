

.container {
    margin: 20px auto;
    width: 80%,
}

.headingContainer {
    background: linear-gradient(to right, rgb(0, 83, 157), rgba(96, 41, 95, 0.99));
    padding:10px 0px;
    margin-bottom: 20px;
}

.divider {
    margin: 20px 0px;
}
@import '../../../../scss/_vars/colors';


.createBtn{
    width: 261px;
    @media screen and (max-width: "990px"){
        width: 100%;
    }
}

.btnWrapper{
    @media screen and (max-width: "990px"){
        padding-top: 8px;
        width: 100%;
    }
}

.subscriptionHeadCard{
    @media screen and (max-width: "990px"){
        flex-direction: column;
        margin-left: 16px;
        margin-right: 16px;

    }
}
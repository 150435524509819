@import '../../../../scss/_vars/colors';

.filterWrapper{
    // min-height: 147px;
    background: linear-gradient(to right,$primary, $secondary);
    border-radius: 0px 0px 16px 16px;
    margin-top: -57px;
    @media(max-width:990px){
        margin-top: -27px
    }
}
@import '../../../../scss/_vars/colors';


.container{
  .filterWrapper {
    // min-height: 147px;
    background: linear-gradient(to right, $primary, $secondary);
    border-radius: 0px 0px 16px 16px;
    margin-top: -57px;
    @media(max-width:990px){
      margin-top: -27px
    }
  }

  @media (max-width:990px) {
    .tabSortFilterContainer{
      display: flex;
      flex-direction: column;
      .reportTypeTabContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .sortFilterContainer{
        max-width: unset;
        width: 100%;
      }
    }
    
  }
}


.ReportTypeTab_wrapper {
  display: inline;

  .tab {
    display: inline;
    margin: 20px 5px 20px 5px;
    color: $primary;
    padding: 4px 12px 4px 12px;

    font-size: 18px;
    font-weight: 700;
    font-family: 'Sofia Sans';

    cursor: pointer;

    border-radius: 5px;
  }

  .active{
    background-color: $primary;
    color: white !important;
  }
}

@media (max-width:550px) {
  .ReportTypeTab_wrapper:first-child {
    margin-bottom: 5px;
  }
}
@import '../../../../../scss/_vars/colors';

.pageWrapper {
  .postWrapper {
    // margin-top: -60px !important;
    background-color: white !important;
    padding: 15px 10px 10px 15px;
    border-radius: 16px 16px 16px 16px !important;
  }

  .headerWrapper {
    min-height: 89px;
    background: linear-gradient(to right, $primary, $secondary);
    border-radius: 0px 0px 16px 16px;
    margin-top: -47px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
  }

  .attachmentSectionWrapper {
    padding: 20px;
    border: 1px dashed lightgray;
    border-radius: 20px;
    position: relative;
    .closeIconWrapper {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  @media (max-width: 1200px) {
    .headerWrapper {
      flex-direction: column;
      margin-top: -24px;
    }

    .actionBtnWrapper {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  @media (max-width: 1000px) {
    .actionBtnWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      button {
        margin: 0;
        margin-top: 10px;
      }
    }
  }

  @media (max-width: 1000px) {
    .actionBtnWrapper {
      width: 98%;
    }
  }
}

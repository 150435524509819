.wrapper{
    border: 1px solid rgba(188, 188, 188, 1);
    border-radius: 25px;
    background-color: rgba(255, 255, 255, 1);
    padding: 15px 21px 15px 30px;
    margin-top: 30px;
    @media screen and (max-width: "990px"){
        margin-top: 10px;
        margin-left: 16px;
        margin-right: 16px;
    }
}

.description{
    overflow-wrap: break-word;
}
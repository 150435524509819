@import '../../scss/_vars/colors';

.header {
  height: 216px;
  background: linear-gradient(to right, $primary, $secondary);

  .menuItem {
    display: flex;
    padding: 4px 12px 4px 12px;
    color: white;
    align-items: center;
  }
  .header_nav_link_active {
    display: flex;
    align-items: center;
    padding: 4px 12px 4px 12px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 1);
    color: $primary;

    svg,
    path {
      fill: $primary !important;
    }
  }


  @media (max-width: 990px) {
    .container{
      justify-content: center;
      align-items: center;
      flex-direction: column;
      // .logoContainer{
      //   // display: none;
      // }
    }
  }

  @media(max-width:990px){
    .menuContainer{
      flex-direction: row;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  @media(max-width:990px){
    .fontSize{
      font-size: 20px;
    }
  }
}

.wrapperPadding {
  padding: 24px 96px 72px 96px;
  @media (max-width: 990px) {
    padding: 17px;

  }

}

@import '../../../../scss/_vars/colors';

.wrapper {
  background-color: white;
  border-radius: 32px;
  padding: 32px;
  margin-top: -25px;

  .menuItem {
    cursor: pointer;
    padding: 20px;
    padding-left: 30px;
  }

  .activeMenuItem {
    background-color: rgba(239, 239, 239, 1);
    border-radius: 40px 0px 0px 40px;
  }

  .activeComponentWrapper {
    background-color: rgba(239, 239, 239, 1);
    padding: 15px 21px 15px 21px;
    border-radius: 0px 25px 25px 0px;
    min-height: 100%;
  }

  @media (max-width:550px) {
    padding: 10px;

    .tabContainer{
      padding: 0px;
    }
  }
}

.gradientText {
  background: linear-gradient(to right, $primary, red);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent; /* Hide the original text color */
}

.paymentForm {
  .cardInput {
    background: white;
    padding: 15px 20px 15px 20px;
    border: 1px solid rgba(188, 188, 188, 1);
    border-radius: 25px;
  }
}

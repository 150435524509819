@import './_vars/colors';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&family=Sofia+Sans:wght@400&display=swap');
body {
  color: black;
  background: rgba(244, 244, 244, 1) !important;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3 {
  font-family: 'FreightSans-Medium', sans-serif;
}

h4,
h5,
h6,
p,
span,
a,
li {
  font-family: 'FreightSans-Medium', sans-serif;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

.styleLink {
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
  background: -webkit-linear-gradient($primary, $secondary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.MuiInputBase-root.Mui-error {
  input {
    border: 1px solid red;
  }
}

.cardWrapper {
  background-color: white;
  border-radius: 24px;
  padding: 24px;
}

.capitalize {
  text-transform: capitalize;
}

.capitalizeFirstWord {
  &::first-letter {
    text-transform: capitalize;
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.disabledButton {
  background: rgba(161, 161, 161, 1) !important;
  color: rgba(255, 255, 255, 1) !important;
  cursor: not-allowed !important;
}

.cursor {
  cursor: pointer;
}

.Mui-disabled.MuiSelect-select {
  background-color: lightgray !important;
}

// @font-face {
//   font-family: 'FreightSans-Black';
//   font-style: normal;
//   font-weight: normal;
//   src: url('../fonts/FreightSans-Light.ttf') format('ttf'),
//     url('../fonts/FreightSans-Light.woff') format('woff');
// }

@font-face {
  font-family: 'FreightSans-Medium';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/FreightSans-Medium.ttf') format('ttf'),
    url('../fonts/FreightSans-Medium.woff') format('woff');
}

@media screen and (max-width: 990px) {
  .cardWrapperContainer, .table-container{
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
}
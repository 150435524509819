.landing-page-container {
    .navbar-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 0px 40px;
        height: 113px;
        align-items: center;
        background-color: white;

        .nav-items-wrapper {
            display: flex;
            align-items: center;
            gap: 80px;
            justify-content: flex-end;
            // width: 80%;
        }

        .navbar-logo {
            cursor: pointer;
            height: 70px;
            width: 147px;
        }

        .nav-items {
            display: flex;
            justify-content: flex-end;
            gap: 50px;

            .nav-item {
                cursor: pointer;
                font-family: 'FreightSans-Medium', sans-serif;
                font-size: 18px;
                font-weight: 600;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: center;
                color: #929292;
            }
        }

        .sign-in-btn {
            min-width: 187px;
            background: #05549F;
            border-radius: 8px;
            font-family: 'FreightSans-Medium', sans-serif;
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
            color: white;
        }
    }

    @media screen and (max-width: 990px) {
        .navbar-wrapper{
            padding: 0px 20px;
            height: 120px;
            flex-direction: column;

            .nav-items-wrapper{
                flex-direction: column;
                gap: 20px;
            }

            .navbar-logo{
                align-self: start;
            }
            .sign-in-btn {
                position: absolute;
                top: 10px;
                right: 10px;
            }

            .nav-items{
                .nav-item{
                    font-size: 14px;
                }
            }

            .sign-in-btn{
                margin-bottom: 20px;
                min-width: 100px;
                font-size: 15px;
            }
        }
    }

    .landing-body {
        background: #151A2F;

        .landing-first-img-wrapper {
            position: relative;

            .landing-first-img-txt-wrapper {
                position: absolute;
                bottom: 10px;
                width: 80%;
                left: 50%;
                transform: translateX(-50%);
            }

            .landing-first-img-txt1 {
                // position: absolute;
                color: white;
                bottom: 200px;
                font-family: 'FreightSans-Medium', sans-serif;
                font-size: 40px;
                font-weight: 700;
                line-height: 58px;
                letter-spacing: 0em;
                text-align: left;
                margin-left: 25px;
            }

            .landing-first-img-txt2 {
                // position: absolute;
                color: white;
                bottom: 155px;
                font-family: 'FreightSans-Medium', sans-serif;
                font-size: 20px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                margin-left: 25px;
                margin-top: 12px;
            }
        }

        .landing-first-img {
            width: 100%;
            height: 650px;
        }

        .take-trial-wrapper {
            min-height: 300px;

            .panes-wrapper {
                justify-content: center;
                display: flex;
                margin-top: 40px;
                gap: 25px;

                .pane-cell {
                    background: linear-gradient(0deg, #00539D, #00539D), linear-gradient(0deg, rgba(0, 83, 157, 0.5), rgba(0, 83, 157, 0.5));
                    border-radius: 8px;
                    min-height: 300px;
                    padding: 25px;
                    width: 26%;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    margin-bottom: 30px;

                    .pane-head {
                        font-family: 'FreightSans-Medium', sans-serif;
                        font-size: 25px;
                        font-weight: 600;
                        line-height: 38px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: white;
                    }

                    .pane-desc {
                        font-family: 'FreightSans-Medium', sans-serif;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 22px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #C9C9C9;
                    }
                }
            }
        }

        .landing-third-wrapper {
            position: relative;
            height: 660px;

            .landing-sec-img {
                width: 100%;
                height: 100%;
            }

            .landing-third-txt-wrapper {
                position: absolute;
                bottom: 300px;
                margin-left: 25px;
                width: 80%;
                left: 50%;
                transform: translateX(-50%);

                .landing-third-img-txt1 {
                    font-family: 'FreightSans-Medium', sans-serif;
                    font-size: 40px;
                    font-weight: 700;
                    line-height: 58px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: white;
                }

                .landing-third-img-txt2 {
                    font-family: 'FreightSans-Medium', sans-serif;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #AFAFAF;
                }
            }

            .third-btn-wrapper {
                display: flex;
                gap: 20px;
                align-items: center;
                margin-top: 20px;

                .third-btn-first {
                    min-width: 290px;
                    height: 52px;
                    border-radius: 8px;
                    border: 1px solid white;
                    font-family: 'FreightSans-Medium', sans-serif;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 22px;
                    letter-spacing: 0em;
                }

                .third-btn-sec {
                    min-width: 290px;
                    height: 52px;
                    border-radius: 8px;
                    border: 1px solid #05549F;
                    background-color: #05549F;
                    font-family: 'FreightSans-Medium', sans-serif;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 22px;
                    letter-spacing: 0em;
                    color: white;

                }
            }
        }
    }
    @media screen and (max-width: 990px) {
        .landing-body{
            .landing-first-img{
                object-fit: cover;
            }
            .landing-first-img-wrapper{
                .landing-first-img-txt1{
                    font-size: 25px;
                    line-height: 35px;
                }
                .landing-first-img-txt2{
                    font-size: 15px;
                }
            }
            .landing-third-wrapper{
                .landing-sec-img{
                    object-fit: cover;
                }
                .landing-third-txt-wrapper{
                    margin-left: 4px;
                    .landing-third-img-txt1{
                        font-size: 25px;
                        line-height: 35px;
                        padding-left: 4%;
                        text-align: center;
                    }
                }
                .third-btn-wrapper{
                    flex-direction: column;
                }
            }
            .take-trial-wrapper{
                .panes-wrapper{
                    flex-direction: column;
                    .pane-cell{
                        width: 80%;
                        margin-left: 10%;
                    }
                }
            }
        }
    }

    .landing-footer {
        padding: 0px 40px;
        background-color: white;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 40px;

        .landing-icon {
            cursor: pointer;
        }

        .icon-wrapper {
            display: flex;
            align-items: center;
            gap: 50px;

            .footer-nav-items {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 40px;

                .footer-nav-item {
                    cursor: pointer;
                    font-family: 'FreightSans-Medium', sans-serif;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 22px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #696969;
                }
            }
        }

        .footer-copyright {
            font-family: 'FreightSans-Medium' ,  sans-serif;
            font-size: 18px;
            font-weight: 600;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: center;
            color: #636363;

            .footer-highlighted {
                color: #00539D;
            }
        }
    }

    @media screen and (max-width: 990px) {
        .landing-footer {
            flex-direction: column;
            padding-top: 10px;
            height: auto;
            .icon-wrapper {
                flex-direction: column;
                gap: 20px;
            }
            .footer-copyright{
                width: 90%;
            }
        }
    }
}
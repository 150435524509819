@import '../../../../scss/_vars/colors';

.cancelBtn {
  width: 200px !important;
  margin-right: 20px !important;
}

.saveBtn {
  width: 200px !important;
}

.publishBtn {
  width: 200px !important;
  margin-right: 20px !important;
}

@media (max-width: 753px){
  .cancelBtn{
    width: 100% !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    margin-right: 0px !important;
  }
  .publishBtn{
    width: 100% !important;
    margin-bottom: 10px !important;
    margin-top: 5px !important;
    margin-right: 0px !important;
  }
  .saveBtn{
    width: 100% !important;
  }
}

.editBtn {
  width: 187px;
}

.removeBtn {
  width: 223px;
}

.buttonFlex{
  display: flex;
  // flex-wrap: wrap;

  @media (max-width: 753px) {
    width: 100%;
    // justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
  }
}

.container {
  display: flex;
  justify-content: flex-end;
  @media (max-width: 900px) {
    flex-direction: column;
  }

  @media (max-width: 900px) {
    .editBtnFull {
        width: 100% !important; 
        margin-top: 4px !important;
        padding-right: 0px;
      }
    .removeBtnPadding {
        padding-right: 0%;
        width: 100%;
        .removeBtnFull {
            width: 100% !important;
            margin-top: 8px !important;
        }
    }
  }

  .filterContainer{
    width: 50%;
    @media (max-width: 900px) {
      flex-direction: column;
      width: 100%;
    }

    .filterBtns{
      @media (max-width: 900px) {
        margin-left: 0px;
        width: 100%;
        padding-left: 0px;
        margin-top: 8px;
      }
    }
  }
}

.titleFont{
  @media (max-width: 990px) {
    font-size: 24px !important;
    width: 125%;
  }
}

.titleContainer{
  // max-width: 75%;

  .dateContainer{
    min-width: max-content;
  }
  .sourceContainer{
    margin-left: 35%;
    max-width: unset;
    width: 100%;
  }
  @media (max-width: 990px) {
    flex-direction: column;
    max-width: 100%;

    .sourceContainer{
      padding: 0px 0px 0px 0px;
      margin: 0px 0px 0px 0px;
      justify-content: start;
    }

    .dateContainer{
      width: 100%;
      max-width: unset;
      justify-content: start;
    }
  }
}

.attachmentSectionWrapper{
  padding: 20px;
  border: 1px dashed lightgray;
  border-radius: 20px;
  position: relative;
  .closeIconWrapper{
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media screen and (max-width: 990px) {
  .headerWrapper{
    margin-left: 16px;
    margin-right: 16px;
  }
}

@media screen and (max-width: 990px) {
  .postWrapper{
    margin-left: 16px;
    margin-right: 16px;
  }
}

.container {
  .editBtn {
    width: 187px;
    @media (max-width: 497px){
      width: 100px;
    }
  }

  .removeBtn {
    width: 223px;
    @media (max-width: 497px){
      width: 100px;
    }
  }

  .news {
    margin-left: 35%;
    max-width: unset;
    width: 100%;
  }

  .chipContainer {
    background: rgba(120, 37, 255, 0.1);
    border: 1px solid rgba(120, 37, 255, 1);
  }

  @media (max-width: 990px) {
    .titleContainer {
      flex-direction: column;
      align-items: start;
    }
    .titlePadding{
      max-width: 100%;
    }
    .titleFont{
      font-size: 24px;
    }

    .dateContainer {
      min-width: max-content;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .news{
      margin-left: 0px;
      justify-content: end;
      margin-bottom: 0px;
      justify-content: start;
    }
  }
}
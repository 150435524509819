@import '../../../../scss/_vars/colors';

@media screen and (max-width: 990px) {
    .wrapper{
      margin-left: 16px;
      margin-right: 16px;
    }
    .createPostWrapper{
        padding-left: 16px !important;
        padding-right: 16px !important;
    }
  }
  
  
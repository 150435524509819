.container {
  position: relative;
  cursor: pointer;
  height: 250px;
  width: 250px;
  // border: 1px solid gray;
  // border-radius: 20px;
  padding: 10px;
  background-color: white;
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  img {
    height: 100%;
    width: 100%;
    aspect-ratio: '1/1';
    object-fit: initial;
  }
  .overlayButton {
    position: absolute !important;
    top: -10px;
    right: -10px;
    z-index: 2;
    background-color: red !important;
    color: white !important;

    :hover {
      background-color: red !important;
      color: white !important;
    }
  }
}

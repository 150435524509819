.editor-content {
  .image {
    clear: both;
    display: table;
    margin: 0.9em auto;
    min-width: 50px;
    text-align: center;
  }
  img {
    max-width: 100% !important;
  }

  .image-style-side {
    float: right;
    max-width: 50%;
  }

  .image_resized {
    box-sizing: border-box;
    display: block;
    max-width: 100%;
  }

  p,
  span {
    font-weight: 100 !important;
  }

  a {
    color: blue;
    text-decoration: underline;
    word-wrap: break-word;
  }
}

.postWrapper {
  margin-top: -60px !important;
  background-color: white !important;
  padding: 15px 10px 10px 15px;
  border-radius: 16px 16px 16px 16px !important;
  @media (max-width: 990px){
    margin-top: -15px !important;
  }

  
  @media (max-width: 990px) {
    .titleContainer {
      flex-direction: column;
      align-items: center;
      max-width: 100%;
      .titlePadding{
        max-width: 100%;
        .titleFont{
          font-size: 24px;
        }
      }
    }

    .dateContainer {
      min-width: max-content;
      margin-top: 10px;
      margin-bottom: 10px;
      @media (max-width: 990px){
        max-width: unset;
        width: 100%;
        justify-content: start;
      }
    }
  }
}

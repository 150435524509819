.document {
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  width: max-content;
}

.container {
  position: relative;
  cursor: pointer;
}

.overlayButton {
  position: absolute !important;
  top: -10px;
  right: -10px;
  z-index: 2;
  background-color: red !important;
  color: white !important;

  :hover {
    background-color: red !important;
    color: white !important;
  }
}

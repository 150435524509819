// @import '../../../../scss/_vars/colors';
@import '../../../../../scss/_vars/colors';

.addUserBtn {
  width: 261px;
}

@media screen and (max-width: 990px) {
  .addUserBtn{
    margin-top: 8px !important;
    width: 100%;
  }
}

.headCard{
  @media screen and (max-width: "990px"){
      flex-direction: column;
      margin-left: 96px;
      margin-right: 96px;

      @media screen and (max-width: 500px) {
          margin-left: 16px;
          margin-right: 16px;
        }
  }
}

@media screen and (max-width: 990px) {
  .usrBtnContainer{
    display: flex;
    flex-direction: column;

    margin-left: 96px;
    margin-right: 96px;

    @media screen and (max-width: 500px) {
      margin-left: 16px;
      margin-right: 16px;
    }
  }
}
@media screen and (max-width: 990px) {
  .usrBtnWrapper{
    width: 100%;
    max-width: unset;
  }
}
@import '../../scss/_vars/colors';


.wrapperPadding{
  padding:12px 96px 12px 96px;

  @media screen and (max-width: 530px) {
    padding: 12px 15px 12px 15px;
  }
}

.backgroundBox {
  height: 235px;
  background: linear-gradient(to right, $primary, $secondary) !important;
}

// .loginBtn{
//   background: -webkit-linear-gradient($primary, $secondary);
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
//   border: 1px solid $primary !important;
// }
.contentWrapper{
  margin-top: -135px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 32px;
  padding: 32px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}
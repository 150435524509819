.contact-us-popup-wrapper {
    .invalid-feedback {
        display: block !important;
    }
    .form-floating{
        input{
            padding-top: .625rem !important;
            padding-bottom: .625rem !important;
        }
    }

    .contact-head {
        font-family: Poppins;
        font-size: 34px;
        font-weight: 600;
        line-height: 51px;
        letter-spacing: -1px;
        text-align: left;
        color: #05549F;
    }

    .grey-border {
        border: 3px solid #AFAFAF;
        border-radius: 5px;
        width: 60px;
    }

    .btn-close {
        height: 24px;
        width: 24px;
        opacity: 1 !important;
    }

    .msg-container {
        input {
            // height: 160px;
        }
    }
    .sendBtn{    background: #05549F;
        height: 50px;
        border-radius: 8px;
        border: 1px solid #05549F;}
}
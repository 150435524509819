.businessHour_timepicker {
  position: relative;
  width: 100%;

  input {
    background: none !important;
    border: none !important;
    padding: 16px 21px 16px 21px;
  }
}

.businessHour_timepicker button {
  opacity: 0;
  z-index: 3;
}

.businessHour_timepicker_icon {
  position: absolute;
  right: 10px;
  top: 12px;
  z-index: 1;
  background-color: white;
}

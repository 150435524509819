@import '../../scss/_vars/colors';

.termsAndPolicy {
  color: $primary;
  text-decoration: underline;

  background: none;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: $primary;
}


.forgotPassword {
  color: $lightGray !important;
  text-decoration: underline;

  background: none;
  -webkit-background-clip: unset;
  -webkit-text-fill-color:  $lightGray !important;
}


.forgotPasswordContainer{
  width: 464px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 50px;
  border-radius: 32px;
  @media (max-width: 990px){
    width: 100%;
  }
}
.forgotPasswordPadding{
  @media (max-width: 990px){
    width: 100%;
    padding: 0px 96px !important;
    @media (max-width: 500px){
      padding: 0px 16px !important;
    }
  }
}

.loginRegDirection {
  @media (max-width: 990px) {
    display: flex !important;
    flex-direction: column !important;
    height: auto !important;
  }
}

.loginBackgroundImage {
  // background-image: url('../../assets/images/login.png');
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: center;
  // display: initial;
  background-color: rgba(210, 210, 210, 1);
  @media (max-width: 990px){
    background-color: rgb(244 244 244);;
  }
}

.form{
  @media (max-width: 990px){
    max-width: 90%;
    padding-left: 10%;;
    width: 100% !important;
  }
}

.formContainer{
  @media (max-width: 990px){
    width: 100%;
  }
}

.trialSubPlanContainer{
  @media (max-width: 990px){
    padding-left: 96px;
    padding-right: 96px;
    padding-top: 96px;
    @media (max-width: 500px){
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 16px;
    }
  }
}